/* test.css */

/* Customize Slick Slider arrows */
.slick-prev,
.slick-next {
  font-size: 24px;
  color: #000; /* Adjust the color as needed */
}

.slick-prev:hover,
.slick-next:hover {
  color: #555; /* Adjust the hover color as needed */
}

.slick-prev {
  left: -30px; /* Adjust the position of the left arrow */
}

.slick-next {
  right: -30px; /* Adjust the position of the right arrow */
}

.slick-disabled {
  display: none; /* Hide the arrows when they are disabled */
}
