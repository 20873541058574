.scr {
  color: white;
  font-size: 50px;
}
.htwo {
  color: white;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  line-height: 82px;
  visibility: inherit;
}
.hthree {
  color: white;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 38px;
  line-height: 82px;
  visibility: inherit;
}
@media only screen and (max-width: 600px) {
  .scr {
    font-size: 30px; /* Adjust the font size as needed for mobile */
  }
  .htwo {
    color: white;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 10px !important;
    line-height: 2px;
  }
  .hthree {
    color: white;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px !important;
    line-height: 2px;
  }
}

/* Add this CSS to your stylesheet */
@media (max-width: 768px) {
  .text-content {
    width: 80%; /* Adjust width as needed */
    padding: 0 10px; /* Add padding for better readability */
  }

  .text-content h2,
  .text-content h3,
  .text-content p {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

/* .revs {
  height: 681px;
} */
.revs img {
  height: 681px;
}

@media (max-width: 600px) {
  .carousel .slider-wrapper,
  .carousel .slider-wrapper .slider {
    height: 481px; /* Adjust the height as needed */
  }
  .revs img {
    height: 481px;
  }
}
/* Remove width and padding restrictions for the video container */
.text-content {
  /* width: 80%; */ /* Remove or adjust width as needed */
  /* padding: 0 10px; */ /* Remove or adjust padding as needed */
}

/* Set the video width to 100% */
.text-content iframe {
  width: 100%;
}
